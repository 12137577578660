import {
  GroupAction,
  GroupDetail,
  OrganizationUserDto,
} from "@superblocksteam/shared";
import { Modal } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FormItem, FormWrapper } from "components/ui/Form";
import { ModalInnerWrapper } from "components/ui/Modal";
import { useFeatureFlag } from "hooks/ui";
import { Invitee } from "pages/Permissions/constants";
import SearchAndInvite from "pages/components/SearchAndInvite";
import SearchAndInviteV2 from "pages/components/SearchAndInviteV2";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { sendSuccessUINotification } from "utils/notification";
import { postGroup } from "./client";
import { MemberToRender, convertToMemberToRender } from "./constants";

const NewMemberModal = ({
  isModalOpen,
  setIsModalOpen,
  setMembers,
  allUsers,
  group,
  invitees,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMembers: React.Dispatch<React.SetStateAction<MemberToRender[]>>;
  allUsers: OrganizationUserDto[];
  group: GroupDetail;
  invitees: Invitee[];
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const orgId = useSelector(selectOnlyOrganizationId);

  const cleanStates = useCallback(() => {
    setError(undefined);
  }, []);

  const addMembers = useCallback(
    async (emails: string[]) => {
      if (!orgId) return;

      const { group: updatedGroup, error } = await postGroup(orgId, group.id, {
        operations: emails.map((email) => ({
          email,
          action: GroupAction.ADD_MEMBER,
        })),
      });

      if (updatedGroup) {
        setMembers(updatedGroup.members.map(convertToMemberToRender));
        setIsModalOpen(false);
        sendSuccessUINotification({
          message: `${emails.join(", ")} added to ${updatedGroup?.name} group`,
        });
        cleanStates();
      }
      if (error) {
        setError(error);
      }
    },
    [cleanStates, group.id, orgId, setIsModalOpen, setMembers],
  );

  const onCancel = useCallback(() => {
    cleanStates();
    setIsModalOpen(false);
  }, [cleanStates, setIsModalOpen]);

  const parentRef = useRef<HTMLDivElement>(null);

  const isAssignRolesEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  return (
    <Modal
      title={`Add users to  ${group.name} group`}
      open={isModalOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          <FormItem
            label={
              isAssignRolesEnabled
                ? ""
                : "Add existing users or invite new users by email"
            }
          >
            {isAssignRolesEnabled ? (
              <SearchAndInviteV2
                allInvitees={invitees}
                onAddInvitees={({ invitees }) => {
                  return addMembers(invitees.map((invitee) => invitee.id));
                }}
                inviteButtonText="Add"
                parentRef={parentRef}
                noGrouping={true}
                placeholder="Begin typing to select users"
              />
            ) : (
              <SearchAndInvite
                allOptions={invitees}
                onAddInvitees={(invitees, _) => {
                  return addMembers(
                    invitees.map((invitee) => invitee.lookupId),
                  );
                }}
                roles={[]}
                inviteButtonText="Add"
                placeholder="Begin typing to select users"
              />
            )}
          </FormItem>
          <FormItem error={error} hidden={!error} />
        </div>
      </div>
    </Modal>
  );
};

export default NewMemberModal;
