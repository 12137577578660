import { GroupType, OrganizationUserDto } from "@superblocksteam/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { HeaderWrapper } from "components/ui/Page";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { useDebounce, useFeatureFlag } from "hooks/ui";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { getUsersRequest } from "pages/Users/client";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import GroupList from "./GroupList";
import NewGroupModal from "./NewGroupModal";
import NewGroupModalV2 from "./NewGroupModalV2";
import { getGroups } from "./client";
import {
  groupCompareFn,
  GroupToRender,
  convertToGroupToRender,
} from "./constants";

const GroupsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const orgId = useSelector(selectOnlyOrganizationId);
  const [groups, setGroups] = useState<GroupToRender[]>([]);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [users, setUsers] = useState<OrganizationUserDto[]>([]);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    async function loadGroups() {
      if (!orgId) return;
      setGroupsLoading(true);
      const { groups } = await getGroups(orgId);
      if (groups) {
        setGroups(groups.map(convertToGroupToRender));
      }
      setGroupsLoading(false);
    }

    async function loadUsers() {
      if (!orgId) return;
      const users = await getUsersRequest(orgId);
      if (users) {
        setUsers(users);
      }
    }
    loadGroups();
    loadUsers();
  }, [orgId]);

  const updateGroup = useCallback(
    (groupId: string, updateProperties: Partial<GroupToRender>) => {
      setGroups((groups) =>
        groups.map((group) =>
          group.id === groupId ? { ...group, ...updateProperties } : group,
        ),
      );
    },
    [],
  );

  const filteredGroups = useMemo(() => {
    let filtered = groups.filter((group) => group.type !== GroupType.ALL_USERS);
    filtered =
      searchTerm.length > 1
        ? groups.filter((group) =>
            filterBySearch<GroupToRender>(group, searchTerm, ["name"]),
          )
        : filtered;

    return filtered.sort(groupCompareFn);
  }, [groups, searchTerm]);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const isAssignRolesEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );

  return (
    <PageWrapper pageName="Groups">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> Groups </div>
            <div className="page-header-description">
              Efficiently manage access and permissions by organizing your users
              into Groups.{" "}
              <a
                href="https://docs.superblocks.com/account-management-and-permissions/role-based-access-control"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className={SearchContainer}>
            <SearchInput
              placeholder="Search"
              onChange={onSearchChangeDebounced}
              data-test="groups-search-input"
            />
            <AddButton
              text="Add group"
              dataTest="add-group-button"
              onAdd={() => setAddModalOpen(true)}
              disabled={!currentUser?.isAdmin}
              disabledText={
                "Only Admins can create groups. Ask an Admin if you’d like to create a group."
              }
            />
          </div>
          <GroupList
            groups={filteredGroups}
            setGroups={setGroups}
            loading={groupsLoading}
            totalCount={groups.length}
            updateGroup={updateGroup}
          />
          {isAssignRolesEnabled ? (
            <NewGroupModalV2
              setGroups={setGroups}
              isModalOpen={addModalOpen}
              setIsModalOpen={setAddModalOpen}
              allUsers={users}
            />
          ) : (
            <NewGroupModal
              setGroups={setGroups}
              isModalOpen={addModalOpen}
              setIsModalOpen={setAddModalOpen}
              allUsers={users}
            />
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default GroupsPage;
