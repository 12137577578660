import React from "react";
import { Helmet } from "react-helmet";
import TrialExpiredModal from "./TrialExpiredModal";

export const AGENTS_TITLE = "On-Premise Agents";
export const INVITE_MEMBERS_TITLE = "Invite Members";
export const AUDIT_LOGS_TITLE = "Audit Logs";
export const DOCUMENTATION_TITLE = "Documentation";
export const HOME_TITLE = "Home";
export const INTEGRATIONS_TITLE = "Integrations";

export const PROFILES_TITLE = "Profiles";
export const REPOSITORIES_TITLE = "Repositories";
export const PERMISSIONS_TITLE = "Permissions";
export const ROLES_AND_PERMISSIONS_TITLE = "Roles & Permissions";
export const OBSERVABILITY_TITLE = "Observability";
export const SECRETS_MANAGEMENT_TITLE = "Secrets Management";

export const VIDEO_TITLE = "Video";

export const PERSONAL_SETTINGS_TITLE = "Personal Settings";

export const PageWrapper = ({
  children,
  pageName,
}: {
  children: React.ReactNode;
  pageName: string | undefined;
}) => {
  return (
    <>
      <TrialExpiredModal />
      <Helmet titleTemplate="%s - Superblocks" defaultTitle="Superblocks">
        {pageName && <title>{pageName}</title>}
      </Helmet>
      {children}
    </>
  );
};
