import {
  Agent,
  ApplicationSignatureTree,
  ApplicationSignatureTreeUpdate,
  Organization,
  Signature,
  sanitizeV2RequestBody,
  sha256Object,
  updateApplicationHashTree,
} from "@superblocksteam/shared";
import { Block } from "@superblocksteam/types/api/v1";
import { call, put, select } from "redux-saga/effects";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { getOpaAgents } from "legacy/utils/getOpaAgents";
import { callOrchestrator } from "store/slices/apisShared/call-orchestrator";
import { Api } from "store/slices/apisV2/backend-types";
import { selectCurrentApplicationSignatureTree } from "store/slices/application/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { HttpMethod } from "store/utils/client";
import { OrchestratorApiPaths } from "store/utils/orchestrator";
import { HttpError } from "store/utils/types";

export type SignatureResponse = {
  signature: Signature;
};

type ApiResource = {
  api: Api;
};

export type GenericResource = {
  literal: {
    data: any;
    signature?: Signature;
  };
};

export type AnySignedResource = ApiResource | GenericResource;

export function* getShouldSignAndVerify(agents: Agent[]) {
  const resourceSigningEnabled: ReturnType<typeof selectFlagById> =
    yield select(selectFlagById, Flag.ENABLE_RESOURCE_SIGNING);
  return (
    resourceSigningEnabled && agents.every((agent) => !!agent.signingKeyId)
  );
}

const getSanitizedApi = (api: Api): Api => {
  const sanitizedBlocks = (api.blocks ?? [])?.map(
    (block) => sanitizeV2RequestBody(block) as unknown as Block,
  );
  return {
    ...api,
    blocks: sanitizedBlocks,
  };
};

export function* signAndUpdateApi(api: Api) {
  const organization: Organization = yield select(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(organization);
  if (isOnPremise) {
    const agents: Agent[] = yield call(getOpaAgents, api);
    const shouldSign: boolean = yield call(getShouldSignAndVerify, agents);
    if (shouldSign) {
      const branchName: ReturnType<typeof getCurrentBranch> = yield select(
        getCurrentBranch,
      );
      try {
        const signatureResponse: SignatureResponse = yield call(signResource, {
          resource: { api },
          agents,
          organization,
          branchName: branchName?.name,
        });
        api.signature = signatureResponse.signature;
      } catch (e: any) {
        throw e.message
          ? new Error(e.message)
          : new Error("Failed to sign API");
      }
    }
  }
}

export function* updateApplicationSignature(
  updates: ApplicationSignatureTreeUpdate[],
) {
  const organization: Organization = yield select(selectOnlyOrganization);
  const isOnPremise = orgIsOnPremise(organization);
  if (isOnPremise) {
    const agents: Agent[] = yield call(getOpaAgents);
    const shouldSign: boolean = yield call(getShouldSignAndVerify, agents);
    if (shouldSign) {
      // re-hash the page or settings and update the hash tree
      const currentHashTree: ApplicationSignatureTree = yield select(
        selectCurrentApplicationSignatureTree,
      );
      if (!currentHashTree) {
        // TODO construct a new hash tree
        return undefined;
      }
      const updatedHashTree: Awaited<
        ReturnType<typeof updateApplicationHashTree>
      > = yield call(updateApplicationHashTree, {
        currentTree: currentHashTree,
        updates,
      });
      const branch: ReturnType<typeof getCurrentBranch> = yield select(
        getCurrentBranch,
      );
      try {
        const data: string = yield call(sha256Object, updatedHashTree);
        const signatureResponse: SignatureResponse = yield call(signResource, {
          resource: {
            literal: {
              data,
            },
          } as GenericResource,
          agents,
          organization,
          branchName: branch?.name,
        });
        // update hash tree in redux
        yield put({
          type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
          payload: { tree: updatedHashTree },
        });
        return {
          signature: signatureResponse.signature,
          root: updatedHashTree,
        };
      } catch (e: any) {
        throw e.message
          ? new Error(e.message)
          : new Error("Failed to sign API");
      }
    }
  }
  return undefined;
}

/**
 * Calls the orchestrator to sign a resource.
 *
 * @param {{resource: ApiResource | GenericResource, agents: Agents: [], organization: Organization, branchName?: string}} params The resource to sign, the agents to use to sign the resource, the organization to use to sign the resource, and the branch name to sign the resource on.
 * @returns {Promise<SignatureResponse>} A promise that resolves to the signature of the resource.
 * @throws {HttpError} Throws errors when request fails. If the backend cannot sign a resource, the response code will be 400
 */
export const signResource = async ({
  resource,
  agents,
  organization,
  branchName,
}: {
  resource: ApiResource | GenericResource;
  agents: Agent[];
  organization: Organization;
  branchName?: string;
}) => {
  if (agents.length === 0) {
    throw new Error("No agents available to sign the resource");
  }
  const requestResource: Record<string, any> = {
    branchName: branchName ?? "main",
  };
  if ((resource as ApiResource).api) {
    requestResource.api = getSanitizedApi((resource as ApiResource).api);
  } else {
    requestResource.literal = (resource as GenericResource).literal;
  }
  let hadError = false;
  let error: HttpError | undefined;
  const onError = (e: HttpError) => {
    hadError = true;
    error = e;
  };
  const response = await callOrchestrator<SignatureResponse>(
    {
      method: HttpMethod.Post,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      url: OrchestratorApiPaths.SIGN_RESOURCE,
      body: {
        resource: requestResource,
      },
      agents,
      organization,
    },
    {
      onError,
      notifyOnError: false,
    },
  );
  if (hadError || !response || !("signature" in response)) {
    let msg = "Failed to sign resource";
    if (hadError && error) {
      msg += ": " + error.message;
    } else if (response) {
      if ("systemError" in response) {
        msg += ": " + response.systemError;
      }
    }
    throw new Error(msg);
  }
  return response;
};

/**
 * Calls the orchestrator to verify a list of resources. If the verification fails, an exception is thrown.
 */
export const verifyResources = async ({
  resources,
  agents,
  organization,
  branchName,
}: {
  resources: Array<AnySignedResource>;
  agents: Agent[];
  organization: Organization;
  branchName?: string;
}) => {
  const onError = (error: HttpError) => {
    throw error;
  };
  const response = await callOrchestrator<{ keyId: string }>(
    {
      method: HttpMethod.Post,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      url: OrchestratorApiPaths.VERIFY_RESOURCE,
      body: {
        resources: resources.map((res) => {
          if ((res as ApiResource).api) {
            return {
              api: getSanitizedApi((res as ApiResource).api),
              branchName: branchName ?? "main",
            };
          }
          return {
            ...res,
            branchName: branchName ?? "main",
          };
        }),
      },
      agents,
      organization,
    },
    {
      onError,
      notifyOnError: false,
    },
  );
  if (typeof response !== "object" || !("keyId" in response)) {
    const msg =
      response && "systemError" in response
        ? response.systemError
        : "Failed to verify resources";
    throw new Error(msg);
  }
};
