import React, { useCallback } from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import CloseButton from "./CloseButton";
import { RecommendedMultiDropdown } from "./RecommendedMultiDropdown";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "./RecommendedSingleDropdown";

export const SingleFilter = ({
  value,
  options,
  onChange,
  width,
  label,
  placeholder,
}: {
  value?: string | { key: string; value: any };
  options: DropdownOption[];
  onChange: (selectedOption: DropdownOption) => void;
  width?: number;
  label: string;
  placeholder?: string;
}) => {
  const renderFilter = useCallback(
    (option: DropdownOption) => (
      <div style={{ color: colors.GREY_700 }}>
        {label}:&nbsp;<b> {option.displayName}</b>
      </div>
    ),
    [label],
  );

  return (
    <RecommendedSingleDropdown
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disableSearch={true}
      renderSelectedOption={renderFilter}
      width={width}
    />
  );
};

const MultiFilterStyle = styleAsClass`
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  gap: 8px;
  & > div:first-child {
    flex: 0 0 auto;
    margin-top: 1px;
    color: ${colors.GREY_700};
  }
  max-width: calc(100% - 24px);
`;

const FilterInnerStyle = styleAsClass`
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 50px);
  text-overflow: ellipsis;
`;

export const MultiFilter = ({
  selectedItems,
  options,
  onChange,
  width,
  label,
  placeholder,
  enableSelectAll,
  defaultSelectAll,
  showSearchInPopover,
  disableSearch,
  dataTest,
}: {
  selectedItems: DropdownOption[];
  options: DropdownOption[];
  onChange: (selectedItems: DropdownOption[]) => void;
  width?: number;
  label?: string;
  placeholder?: string;
  enableSelectAll: boolean;
  defaultSelectAll?: boolean;
  showSearchInPopover?: boolean;
  disableSearch?: boolean;
  dataTest?: string;
}) => {
  const onClear = useCallback(
    (e: any) => {
      onChange(defaultSelectAll ? options : []);
      e?.stopPropagation();
    },
    [defaultSelectAll, onChange, options],
  );

  const renderFilter = useCallback(
    (selectedOptions: DropdownOption[]) => {
      const allSelected = selectedOptions.length === options.length;
      const showClearButton =
        (defaultSelectAll && !allSelected) ||
        (!defaultSelectAll && selectedOptions.length > 0);
      return (
        <div className={MultiFilterStyle}>
          <div className={FilterInnerStyle}>
            {label && `${label}:`}
            <b
              style={
                selectedOptions.length === 1 || allSelected
                  ? undefined
                  : { color: colors.ACCENT_BLUE_500 }
              }
            >
              {" "}
              {selectedOptions.length === 1
                ? selectedOptions[0].displayName
                : allSelected
                ? "All"
                : `${selectedOptions.length} selected`}{" "}
            </b>{" "}
          </div>
          {showClearButton && <CloseButton onClick={onClear} />}
        </div>
      );
    },
    [defaultSelectAll, label, onClear, options.length],
  );

  return (
    <RecommendedMultiDropdown
      options={options}
      placeholder={placeholder}
      selectedItems={selectedItems}
      onChange={onChange}
      disableSearch={disableSearch}
      showSearchInPopover={showSearchInPopover}
      renderSelectedItems={renderFilter}
      width={width}
      enableSelectAll={enableSelectAll}
      dataTest={dataTest ?? ""}
    />
  );
};
