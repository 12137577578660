import {
  DropdownOption,
  GroupBrief,
  GroupType,
  OrganizationUserDto,
  UserType,
} from "@superblocksteam/shared";
import { Tabs } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { MultiFilter } from "components/ui/Filter";
import { HeaderWrapper } from "components/ui/Page";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { useDebounce, useFeatureFlag } from "hooks/ui";
import { getGroupsForOrg } from "pages/Permissions/client";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import InviteOrgUserModal from "pages/components/invitation/InviteOrgUserModal";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { colors } from "styles/colors";
import UserList from "./UserList";
import { getUsersRequest } from "./client";
import {
  StatusType,
  UserToRender,
  convertToUserToRender,
  userCompareFn,
} from "./constants";

type UsersTabKey = "superblocks" | "embed";

const statusOptions: (DropdownOption & { value: StatusType })[] = [
  {
    displayName: "Active",
    value: "active",
    key: "active",
  },
  {
    displayName: "Deactivated",
    value: "deactivated",
    key: "desactivated",
  },
  {
    displayName: "Invited",
    value: "invited",
    key: "invited",
  },
];

const UsersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [users, setUsers] = useState<UserToRender[]>([]);
  const currentOrg = useSelector(selectOnlyOrganization);
  const [usersLoading, setUsersLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined,
  );
  const [openedTab, setOpenedTab] = useState<UsersTabKey>("superblocks");
  const userType =
    openedTab === "embed" ? UserType.EXTERNAL : UserType.SUPERBLOCKS;

  const onTabChange = useCallback((key: string) => {
    setOpenedTab(key as UsersTabKey);
  }, []);

  const [groups, setGroups] = useState<GroupBrief[]>([]);
  const allUsersGroup: GroupBrief | undefined = useMemo(() => {
    return groups.find(
      (group: GroupBrief) => group.type === GroupType.ALL_USERS,
    );
  }, [groups]);

  const [statusFilter, setStatusFilter] =
    useState<DropdownOption[]>(statusOptions);

  useEffect(() => {
    async function getUsers() {
      try {
        setSearchTerm("");
        setUsers([]);
        setUsersLoading(true);

        const users = await getUsersRequest(currentOrg.id, userType);
        if (users) {
          setUsers(users.map(convertToUserToRender));
        }

        const { groups } = await getGroupsForOrg(currentOrg.id);
        if (groups) {
          setGroups(groups);
        }
        setLoadingError(undefined);
      } catch (e: any) {
        setLoadingError(e.message ?? "Failed to fetch users");
      } finally {
        setUsersLoading(false);
      }
    }
    getUsers();
  }, [currentOrg.id, userType]);

  const onUserUpdated = useCallback(
    (userId: string, updates: Partial<UserToRender>) => {
      setUsers((users) =>
        users.map((user) =>
          user.id === userId ? { ...user, ...updates } : user,
        ),
      );
    },
    [],
  );

  const onUsersAdded = useCallback((newUsers: OrganizationUserDto[]) => {
    setUsers(newUsers.map(convertToUserToRender));
  }, []);

  const filterUsers = useMemo(() => {
    const selectedStatuses = statusFilter.map(
      (option) => option.value,
    ) as StatusType[];

    let filtered =
      searchTerm.length > 1
        ? users.filter((user) =>
            filterBySearch<UserToRender>(user, searchTerm, ["name", "email"]),
          )
        : users;
    // TODO: remove this check once we handle it from backend. We should not return superblocks super user when customer call org users endpoint
    filtered = filtered.filter(
      (user) =>
        !(
          currentOrg.name !== "superblockshq.com" &&
          user.email.endsWith("@superblockshq.com")
        ),
    );
    filtered =
      statusFilter.length === statusOptions.length
        ? filtered
        : filtered.filter((user) => selectedStatuses.includes(user.status));

    return filtered.sort(userCompareFn);
  }, [currentOrg.name, searchTerm, statusFilter, users]);

  const handleAddUserCancel = useCallback(() => {
    setIsInviteModalOpen(false);
  }, []);

  const embedEnabled = useFeatureFlag(Flag.ENABLE_EMBED);

  const SbUsersList = useMemo(
    () => (
      <>
        <div className={SearchContainer}>
          <SearchInput
            placeholder="Search"
            onChange={onSearchChangeDebounced}
            data-test="users-search-input"
          />
          <MultiFilter
            selectedItems={statusFilter}
            options={statusOptions}
            onChange={(selectedOptions) => setStatusFilter(selectedOptions)}
            label="Status"
            width={163}
            placeholder="Filter by status"
            enableSelectAll={true}
            defaultSelectAll={true}
            disableSearch={true}
          />
          <AddButton
            text="Invite user"
            onAdd={() => setIsInviteModalOpen(true)}
          />
        </div>
        {loadingError ? (
          <div style={{ color: colors.RED_500 }}>{loadingError}</div>
        ) : (
          allUsersGroup && (
            <UserList
              type={
                openedTab === "embed" ? UserType.EXTERNAL : UserType.SUPERBLOCKS
              }
              users={filterUsers}
              setUsers={setUsers}
              loading={usersLoading}
              totalCount={users.length}
              groupId={allUsersGroup.id}
              updateUser={onUserUpdated}
            />
          )
        )}
      </>
    ),
    [
      allUsersGroup,
      filterUsers,
      loadingError,
      onSearchChangeDebounced,
      openedTab,
      statusFilter,
      users.length,
      usersLoading,
      onUserUpdated,
    ],
  );

  return (
    <PageWrapper pageName="Users">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> Users </div>
          </div>
          {embedEnabled ? (
            <Tabs
              defaultActiveKey="superblocks"
              activeKey={openedTab}
              onChange={onTabChange}
            >
              <Tabs.TabPane tab="Superblocks users" key="superblocks">
                {SbUsersList}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Embed users" key="embed">
                <div className={SearchContainer}>
                  <SearchInput
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    data-test="users-search-input"
                  />
                </div>
                {loadingError ? (
                  <div style={{ color: colors.RED_500 }}>{loadingError}</div>
                ) : (
                  allUsersGroup && (
                    <UserList
                      type={
                        openedTab === "embed"
                          ? UserType.EXTERNAL
                          : UserType.SUPERBLOCKS
                      }
                      users={filterUsers}
                      setUsers={setUsers}
                      loading={usersLoading}
                      totalCount={users.length}
                      groupId={allUsersGroup.id}
                      updateUser={onUserUpdated}
                    />
                  )
                )}
              </Tabs.TabPane>
            </Tabs>
          ) : (
            SbUsersList
          )}
          <InviteOrgUserModal
            isVisible={isInviteModalOpen}
            onClose={handleAddUserCancel}
            onUsersAdded={onUsersAdded}
          />
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default UsersPage;
