import { FontFamily, TextStyleBlock } from "@superblocksteam/shared";
import { Select, Tooltip } from "antd";
import { get } from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { ReactComponent as ConfigureIcon } from "assets/icons/common/customization.svg";
import { ReactComponent as UndoIcon } from "assets/icons/common/undo.svg";
import { ColorPicker } from "components/ui/ColorPicker";
import DropdownSelect from "components/ui/DropdownSelect";
import {
  type InputNumberControlProps,
  type UnitOption,
} from "legacy/components/propertyControls/InputNumberControl";
import { GeneratedTheme } from "legacy/themes";
import { DEFAULT_FONT_WEIGHTS } from "legacy/themes/typefaceConstants";
import {
  getAvailableFontFamilies,
  getFontFamilyOptions,
  loadFont,
} from "legacy/themes/typefaces/utils";
import {
  DISABLED_PROPERTIES_BY_TYPOGRAHY,
  LOCKED_PROPERTIES_BY_TYPOGRAHY,
  LOCKED_TOOLTIP_BY_TYPOGRAPHY,
} from "legacy/themes/typographyConstants";
import { extractPixels, getDefaultTypographies } from "legacy/themes/utils";
import { PixelInput } from "pages/Editors/AppBuilder/Sidebar/PixelInput";
import { colors } from "styles/colors";
import { InputLabel } from "./Shared";
import { WrappedInputNumberControl } from "./WrappedInputNumberControl";

const StyledSelect = styled(Select)`
  .ant-select-clear {
    opacity: 1;
    top: 13px;
    right: 36px;
  }
`;

const ManageTypefaceOption = styled.div`
  .divider {
    width: 100%;
    height: 1px;
    background: ${colors.GREY_100};
    margin: 6px 2px;
  }
  .option {
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${colors.ACCENT_BLUE_500};
    font-size: 12px;
    border-radius: 4px;
    min-height: 32px;
    padding: 5px 12px;
    cursor: pointer;
    margin: 0px 4px;
    &:hover {
      background: ${colors.ACCENT_BLUE_500_25};
    }
  }
`;

const shouldHideControl = (typography: string, key: keyof TextStyleBlock) => {
  return DISABLED_PROPERTIES_BY_TYPOGRAHY[typography]?.includes(key);
};

const shouldLockControl = (typography: string, key: keyof TextStyleBlock) => {
  return LOCKED_PROPERTIES_BY_TYPOGRAHY[typography]?.includes(key);
};

export const TypefaceDropdown = ({
  onChange,
  availableFonts,
  value,
  openFontPanel,
  isDisabled,
  themeDefault,
  dataTest,
  typography,
  themeVersion,
}: {
  onChange: (value: string) => void;
  availableFonts?: Record<string, FontFamily>;
  value: string;
  openFontPanel: () => void;
  isDisabled?: boolean;
  themeDefault?: string;
  dataTest?: string;
  typography?: string;
  themeVersion?: number;
}) => {
  const availableFontFamilyMap = getAvailableFontFamilies(availableFonts);

  const typefaceOptions = useMemo(() => {
    const typefaceOptions = getFontFamilyOptions(availableFonts);
    if (themeDefault) {
      const defaultSelectedOption = typefaceOptions.find(
        (option) => option.value === themeDefault,
      );
      typefaceOptions.unshift({
        label: (
          <span
            style={{
              fontFamily: `${defaultSelectedOption?.value}, var(--font-family)`,
            }}
          >
            Theme default ({defaultSelectedOption?.label})
          </span>
        ),
        value: "inherit",
      });
    }
    return typefaceOptions;
  }, [availableFonts, themeDefault]);

  const selectedOption = useMemo(() => {
    return (
      typefaceOptions.find((option) => option.value === value)?.value ??
      "inherit"
    );
  }, [value, typefaceOptions]);

  const defaultTypographies = useMemo(() => {
    return getDefaultTypographies(themeVersion);
  }, [themeVersion]);

  let defaultFontFamily = typography
    ? get(defaultTypographies, `${typography}.fontFamily`)
    : "inherit";
  if (!availableFontFamilyMap[defaultFontFamily]) {
    defaultFontFamily = "inherit";
  }
  const allowClear =
    typography != null &&
    value !== defaultFontFamily &&
    selectedOption !== defaultFontFamily &&
    themeDefault != null;

  const handleChange = useCallback(
    (_selected: unknown) => {
      const selected = (_selected ?? defaultFontFamily ?? "inherit") as string;
      onChange(selected as string);
      const fontFamily = availableFontFamilyMap[selected];
      fontFamily && loadFont(fontFamily); // get a head start on loading the font
    },
    [onChange, availableFontFamilyMap, defaultFontFamily],
  );

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return (
    <StyledSelect
      data-test={dataTest ?? "typography-typeface"}
      options={typefaceOptions}
      placeholder="Typeface"
      value={selectedOption}
      suffixIcon={<ChevronDown color={colors.GREY_300} />}
      onChange={handleChange}
      clearIcon={
        <Tooltip title="Reset to theme default typeface">
          <UndoIcon />
        </Tooltip>
      }
      allowClear={allowClear}
      disabled={isDisabled}
      open={isDropdownOpen}
      showSearch={true}
      onDropdownVisibleChange={setIsDropdownOpen}
      dropdownRender={(menu) => (
        <>
          {menu}
          <ManageTypefaceOption>
            <div className="divider" />
            <div
              onClick={() => {
                openFontPanel();
                setIsDropdownOpen(false);
              }}
              className="option"
            >
              <ConfigureIcon />
              <span>Manage typefaces</span>
            </div>
          </ManageTypefaceOption>
        </>
      )}
    />
  );
};

export interface TypographyControlProps {
  onChange: (
    typography: string,
    changes: Record<string, string | number>,
  ) => void;
  typography: string;
  generatedTheme: GeneratedTheme;
  typographySettings: TextStyleBlock;
  propertyName: keyof TextStyleBlock;
  label: string;
  isLocked?: boolean;
  warning?: string;
  openFontPanel: () => void;
}

const PIXEL_OPTION: UnitOption = {
  label: "Pixels",
  value: "px",
  subText: "Fixed value that remains constant",
};

const SCALE_HELP_TEXT_LINE_HEIGHT =
  "A value to be multiplied by font size. Equivalent to unitless in CSS";
const SCALE_HELP_TEXT_LETTER_SPACING =
  "A value to be multiplied by font size. Equivalent to em in CSS";

const EM_OPTION: UnitOption = {
  label: "Scale",
  value: "em",
  subText: SCALE_HELP_TEXT_LETTER_SPACING,
};

const SCALE_OPTION: UnitOption = {
  label: "Scale",
  value: "",
  subText: SCALE_HELP_TEXT_LINE_HEIGHT,
};

const NORMAL_OPTION: UnitOption = {
  label: "Normal",
  value: "normal",
  numberless: true,
  subText: "Browser default for the font family",
};

const isScaledUnit = (unit: string | undefined) => unit === "em" || unit === "";

export const getNewValueOnUnitChange = ({
  oldUnit,
  newUnit,
  value,
  currentFontSize,
  defaultRatio = 1,
}: {
  oldUnit: string | undefined;
  newUnit: string;
  value: unknown;
  currentFontSize: number;
  defaultRatio?: number;
}) => {
  if (oldUnit === "px" && isScaledUnit(newUnit)) {
    const ratio = Number(value) / currentFontSize;
    if (Number.isFinite(ratio)) {
      return Number(ratio.toPrecision(2));
    }
  } else if (isScaledUnit(oldUnit) && newUnit === "px") {
    const resultPx = currentFontSize * Number(value);
    if (Number.isFinite(resultPx)) {
      return Number(resultPx.toPrecision(2));
    }
  } else if (oldUnit === "normal") {
    if (isScaledUnit(newUnit)) {
      return defaultRatio;
    } else if (newUnit === "px") {
      const ratio = defaultRatio * currentFontSize;
      return Number(ratio.toPrecision(2));
    }
  }
  return typeof value === "number" ? value : undefined; // dont change otherwise
};

const useTransformValueOnUnitChange = (
  typographySettings: TypographyControlProps["typographySettings"],
  defaultRatio: number = 1,
) => {
  const currentFontSize = extractPixels(typographySettings.fontSize);

  return useCallback<
    NonNullable<InputNumberControlProps["transformValueOnUnitChange"]>
  >(
    ({ oldUnit, newUnit, value }) => {
      return getNewValueOnUnitChange({
        oldUnit,
        newUnit,
        value,
        currentFontSize,
        defaultRatio,
      });
    },
    [currentFontSize, defaultRatio],
  );
};

export const FONT_SIZE_UNIT_OPTIONS = [PIXEL_OPTION];

const FontSizeControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings, propertyName } = props;

  return (
    <PixelInput
      title="Font Size"
      dataTest="typography-font-size-input"
      value={parseInt(typographySettings.fontSize)}
      min={1}
      onChange={(value) => {
        onChange(typography, { [propertyName]: `${value}px` });
      }}
      style={{ flexGrow: 1, minWidth: 0 }}
      disabled={props.isLocked}
    />
  );
};

const TYPOGRAPHY_DEFAULT_KEYS: Array<keyof GeneratedTheme["colors"]> = [
  "neutral900",
  "neutral700",
  "neutral500",
  "neutral300",
  "primary500",
];

const ColorControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, generatedTheme, typographySettings } = props;

  const presetOptions = useMemo(() => {
    return [
      {
        title: "Presets",
        options: TYPOGRAPHY_DEFAULT_KEYS.map((key) => ({
          hexValue: generatedTheme.colors[key] as string,
          value: `colors.${key}`, // NOT a binding, this is not going to be evaluated
          key: `colors.${key}`,
          displayName: key,
        })),
      },
    ];
  }, [generatedTheme.colors]);

  const defaultTypographies = useMemo(() => {
    return getDefaultTypographies(props.generatedTheme.version);
  }, [props.generatedTheme.version]);

  const defaultColor = get(
    defaultTypographies,
    `${typography}.textColor.default`,
  );
  const defaultSelectedOption = useMemo(() => {
    return presetOptions[0].options.find(
      (option) => option.key === defaultColor,
    );
  }, [presetOptions, defaultColor]);

  const selectedColorOption = useMemo(() => {
    // if using default, just return null and fallback to default
    if (
      typographySettings.textColor.default ===
      get(defaultTypographies, `${typography}.textColor.default`)
    ) {
      return undefined;
    }
    const selectedPreset = presetOptions[0].options.find(
      (option) => option.key === typographySettings.textColor.default,
    );

    return selectedPreset ?? typographySettings.textColor.default;
  }, [
    typographySettings.textColor.default,
    presetOptions,
    typography,
    defaultTypographies,
  ]);

  return (
    <ColorPicker
      dataTest="typography-color"
      onColorSelect={(color, hexValue) => {
        onChange(typography, { "textColor.default": color ?? defaultColor });
      }}
      defaultSelectedColor={defaultSelectedOption}
      selectedColor={selectedColorOption}
      showOpacity={true}
      showInputsInPopover={true}
      isClearable={defaultSelectedOption != null}
      presetColorChoices={presetOptions}
      clearBehavior="clear"
      disabled={props.isLocked}
      placeholder={props.isLocked ? "Inherited" : "Select a color"}
    />
  );
};

const TypeFaceControlComponent = (props: TypographyControlProps) => {
  const {
    onChange,
    typography,
    typographySettings,
    generatedTheme,
    openFontPanel,
  } = props;
  const availableFontFamilyMap = getAvailableFontFamilies(
    generatedTheme.availableFonts,
  );

  const handleChange = useCallback(
    (selected: string) => {
      const fontFamily = availableFontFamilyMap[selected];
      const changes: Record<string, string | number> = {
        fontFamily: selected as string,
      };
      const existingFontWeight = typographySettings.fontWeight;
      if (
        fontFamily?.weights &&
        fontFamily?.weights.length > 0 &&
        !fontFamily.weights?.includes(Number(existingFontWeight))
      ) {
        changes.fontWeight = fontFamily.weights[0];
      }
      onChange(typography, changes);
    },
    [availableFontFamilyMap, onChange, typography, typographySettings],
  );

  return (
    <TypefaceDropdown
      onChange={handleChange}
      value={typographySettings.fontFamily}
      availableFonts={generatedTheme.availableFonts}
      openFontPanel={openFontPanel}
      isDisabled={props.isLocked}
      themeDefault={generatedTheme.fontFamily}
      typography={typography}
    />
  );
};

export const getFontWeightOptions = () => {
  const fontWeightOptions: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "Thin (100)",
      value: "100",
    },
    {
      label: "Extra light (200)",
      value: "200",
    },
    {
      label: "Light (300)",
      value: "300",
    },
    {
      label: "Normal (400)",
      value: "400",
    },
    {
      label: "Medium (500)",
      value: "500",
    },
    {
      label: "Semi-bold (600)",
      value: "600",
    },
    {
      label: "Bold (700)",
      value: "700",
    },
    {
      label: "Extra bold (800)",
      value: "800",
    },
    {
      label: "Black (900)",
      value: "900",
    },
  ];

  return fontWeightOptions;
};

const FontWeightControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings, generatedTheme } = props;
  const fontWeightOptions = getFontWeightOptions();

  let fontFamily = typographySettings.fontFamily;

  if (fontFamily === "inherit") {
    fontFamily = props.generatedTheme.fontFamily;
  }

  const availableWeights = useMemo(() => {
    return (
      getAvailableFontFamilies(generatedTheme.availableFonts)?.[fontFamily]
        ?.weights ?? DEFAULT_FONT_WEIGHTS
    );
  }, [generatedTheme.availableFonts, fontFamily]);

  // Remove any fontWeightOptions that are not available for the selected font
  const filteredFontWeightOptions = fontWeightOptions.filter((option) =>
    availableWeights.includes(Number(option.value)),
  );

  const currentValue = typographySettings.fontWeight;
  const selectedOption = useMemo(() => {
    return filteredFontWeightOptions.find(
      (option) => String(option.value) === String(currentValue),
    );
  }, [currentValue, filteredFontWeightOptions]);

  return (
    <DropdownSelect
      data-test="typography-font-weight"
      options={filteredFontWeightOptions}
      placeholder="Font weight"
      value={selectedOption?.value}
      onChange={(selected) => {
        onChange(typography, { fontWeight: selected });
      }}
      disabled={props.isLocked}
    />
  );
};

export const FONT_STYLE_OPTIONS = [
  { label: "Normal", value: "normal" },
  { label: "Italic", value: "italic" },
];
const FontStyleControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings } = props;

  const currentValue = typographySettings.fontStyle ?? "normal";

  const selectedOption = useMemo(() => {
    return FONT_STYLE_OPTIONS.find((option) => option.value === currentValue);
  }, [currentValue]);

  return (
    <DropdownSelect
      data-test="typography-font-style"
      options={FONT_STYLE_OPTIONS}
      placeholder="Font Style"
      value={selectedOption?.value}
      onChange={(selected) => {
        onChange(typography, { fontStyle: selected });
      }}
      disabled={props.isLocked}
    />
  );
};

export const LINE_HEIGHT_UNIT_OPTIONS = [
  PIXEL_OPTION,
  SCALE_OPTION,
  NORMAL_OPTION,
];

const LineHeightControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings } = props;

  const transformValueOnUnitChange = useTransformValueOnUnitChange(
    typographySettings,
    1.2,
  );

  return (
    <WrappedInputNumberControl
      dataTest="typography-line-height"
      onPropertyChange={(propertyName, value) => {
        onChange(typography, { [propertyName]: value });
      }}
      propertyName="lineHeight"
      unitOptions={LINE_HEIGHT_UNIT_OPTIONS}
      defaultUnit="px"
      propertyValue={typographySettings.lineHeight}
      isDisabled={props.isLocked}
      transformValueOnUnitChange={transformValueOnUnitChange}
      warning={props.warning}
    />
  );
};

export const TEXT_TRANSFORM_OPTIONS = [
  { label: "None", value: "none" },
  { label: "Uppercase", value: "uppercase" },
  { label: "Lowercase", value: "lowercase" },
  { label: "Capitalize", value: "capitalize" },
];

const TextTransformControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings } = props;

  const currentValue = typographySettings.textTransform ?? "none";

  const selectedOption = useMemo(() => {
    return TEXT_TRANSFORM_OPTIONS.find(
      (option) => option.value === currentValue,
    );
  }, [currentValue]);

  return (
    <DropdownSelect
      data-test="typography-text-transform"
      options={TEXT_TRANSFORM_OPTIONS}
      placeholder="Text Transform"
      value={selectedOption?.value}
      onChange={(selected) => {
        onChange(typography, { textTransform: selected });
      }}
      disabled={props.isLocked}
    />
  );
};

export const LETTER_SPACING_UNIT_OPTIONS = [
  PIXEL_OPTION,
  EM_OPTION,
  NORMAL_OPTION,
];

const LetterSpacingControlComponent = (props: TypographyControlProps) => {
  const { onChange, typography, typographySettings } = props;

  const transformValueOnUnitChange = useTransformValueOnUnitChange(
    typographySettings,
    0,
  );

  return (
    <WrappedInputNumberControl
      dataTest="typography-letter-spacing"
      onPropertyChange={(propertyName, value) => {
        onChange(typography, { [propertyName]: value });
      }}
      propertyName="letterSpacing"
      unitOptions={LETTER_SPACING_UNIT_OPTIONS}
      defaultUnit="px"
      propertyValue={typographySettings.letterSpacing}
      isDisabled={props.isLocked}
      transformValueOnUnitChange={transformValueOnUnitChange}
    />
  );
};

const FieldWrapper = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

const withConditionalRender = (
  WrappedComponent: (props: TypographyControlProps) => JSX.Element,
) => {
  const ComponentWithConditionalRender = (props: TypographyControlProps) => {
    if (shouldHideControl(props.typography, props.propertyName)) {
      return null;
    }
    const isLocked = shouldLockControl(props.typography, props.propertyName);
    return (
      <>
        <InputLabel>{props.label}</InputLabel>
        <Tooltip
          title={
            isLocked
              ? LOCKED_TOOLTIP_BY_TYPOGRAPHY[props.typography] ??
                "This property is inherited"
              : undefined
          }
        >
          {/* tooltips directly around disabled antd inputs don't work, hence the extra div :/ */}
          <FieldWrapper>
            <WrappedComponent {...props} isLocked={isLocked} />
          </FieldWrapper>
        </Tooltip>
      </>
    );
  };

  ComponentWithConditionalRender.displayName = `withConditionalRender(${
    WrappedComponent.name || "Component"
  })`;

  return ComponentWithConditionalRender;
};

/* conditionally render based on the typography type */
export const FontSizeControl = withConditionalRender(FontSizeControlComponent);
export const ColorControl = withConditionalRender(ColorControlComponent);
export const TypeFaceControl = withConditionalRender(TypeFaceControlComponent);
export const FontWeightControl = withConditionalRender(
  FontWeightControlComponent,
);
export const FontStyleControl = withConditionalRender(
  FontStyleControlComponent,
);
export const LineHeightControl = withConditionalRender(
  LineHeightControlComponent,
);
export const TextTransformControl = withConditionalRender(
  TextTransformControlComponent,
);
export const LetterSpacingControl = withConditionalRender(
  LetterSpacingControlComponent,
);
