import React from "react";
import { DefaultAvatarColor, generateHSL } from "styles/colorGeneration";
import { styleAsClass } from "styles/styleAsClass";

export const StyledAvatarWrapper = styleAsClass`
  color: ${DefaultAvatarColor.FRONT};
  background: ${DefaultAvatarColor.BACKGROUND};
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColoredAvatar = ({
  name,
  children,
}: {
  name?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={StyledAvatarWrapper}
      style={
        name
          ? {
              color: generateHSL(name).front,
              background: generateHSL(name).background,
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};

export default ColoredAvatar;
