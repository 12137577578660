import { ApplicationScope, RouteDef } from "@superblocksteam/shared/src/types";
import { find, get } from "lodash";
import { memoize as proxyMemoize } from "proxy-memoize";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { WidgetTypes, PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import {
  getNestedItemDisplayName,
  getWidgetDisplayName,
} from "legacy/pages/Editor/Explorer/helpers";
import {
  ItemKinds,
  ItemTypeNonWidget,
  ItemWithPropertiesType,
  PropertyPaneItem,
} from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { extractPartsFromNestedItemId } from "legacy/pages/Editor/PropertyPane/NestedItemsUtils";
import {
  CanvasWidgetsReduxState,
  ReduxPageType,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { PropertyPaneReduxState } from "legacy/reducers/uiReducers/propertyPaneReducer";

import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import { getAllEvents } from "store/slices/application/events/selectors";
import { getAllStateVars } from "store/slices/application/stateVars/selectors";
import { getAllTimers } from "store/slices/application/timers/selectors";
import { selectFlags } from "store/slices/featureFlags/selectors";
import { type AppState } from "store/types";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { getAllParentPaths } from "utils/dottedPaths";
import unreachable from "utils/unreachable";
import { getCanvasWidgets } from "./entitiesSelector";
import { createMarkedSelector } from "./markedSelector";
import { getRoutes } from "./routeSelectors";
import type { NestedItem, Widget } from "hooks/ui/useNavigateTo";
import type { WidgetProps } from "legacy/widgets";

const getPropertyPaneState = (state: AppState) => state.legacy.ui.propertyPane;

export const getOpenPanels = (state: AppState): string[] =>
  state.legacy.ui.propertyPane.openPanels;

export const getCurrentPropertyItem = createMarkedSelector(
  "getCurrentPropertyItem",
)(
  getPropertyPaneState,
  (propertyPane: PropertyPaneReduxState) => propertyPane.item,
);

export const getPropertyPanelScope = createSelector(
  [getCurrentPropertyItem],
  (item) => item?.scope || ApplicationScope.PAGE,
);

export const getPropertyIsFocused = createCachedSelector(
  getPropertyPaneState,
  (_: any, propertyPath: string) => propertyPath,
  (propertyPane, propertyPath) =>
    propertyPane.activePropertyPath === propertyPath ||
    propertyPane.hoveredPropertyPath === propertyPath,
)((_, propertyPath) => propertyPath);

export const getPaddingIsFocused = (state: AppState) =>
  getPropertyIsFocused(state, "padding");
export const getMarginIsFocused = (state: AppState) =>
  getPropertyIsFocused(state, "margin");
export const getSpacingIsFocused = (state: AppState) =>
  getPropertyIsFocused(state, "spacing");

export const getCurrentItemType = createMarkedSelector("getCurrentItemType")(
  getCurrentPropertyItem,
  getCanvasWidgets,
  getAllStateVars,
  getAllTimers,
  getAllEvents,
  (item, widgets, stateVars, timers, events) => {
    let type: ItemWithPropertiesType | undefined;
    if (item) {
      switch (item.kind) {
        case ItemKinds.WIDGET: {
          type = widgets?.[item.id]?.type;
          break;
        }
        case ItemKinds.NESTED_ITEM: {
          const { widgetId, path } = extractPartsFromNestedItemId(item.id);
          const nestedItem = get(widgets[widgetId], path ?? "");
          if (nestedItem) type = ItemTypeNonWidget.NESTED_ITEM;
          break;
        }
        case ItemKinds.STATE_VAR: {
          const stateVar = stateVars?.[item.id];
          if (stateVar) type = ItemTypeNonWidget.STATE_VAR;
          break;
        }
        case ItemKinds.EMBED_PROP: {
          const embedProp =
            widgets[PAGE_WIDGET_ID]?.embedding?.propertyMap?.[item.id];
          if (embedProp) type = ItemTypeNonWidget.EMBED_PROP;
          break;
        }
        case ItemKinds.TIMER: {
          const timer = timers[item.id];
          if (timer) type = ItemTypeNonWidget.TIMER;
          break;
        }
        case ItemKinds.ROUTE: {
          type = ItemTypeNonWidget.ROUTE;
          break;
        }
        case ItemKinds.API_V1:
        case ItemKinds.API_V2:
          // APIs are not viewable in the property pane
          type = undefined;
          break;
        case ItemKinds.CUSTOM_EVENT: {
          const event = events[item.id];
          if (event) type = ItemTypeNonWidget.CUSTOM_EVENT;
          break;
        }
        default:
          unreachable(item.kind);
      }
    }
    return type;
  },
);

export const getCurrentWidgetProperties = createMarkedSelector(
  "getCurrentWidgetProperties",
)(
  getCanvasWidgets,
  getCurrentPropertyItem,
  (widgets: CanvasWidgetsReduxState, item: PropertyPaneItem | undefined) => {
    return item?.id && widgets ? widgets[item?.id] : undefined;
  },
);

export const getCurrentWidgetId = createMarkedSelector("getCurrentWidgetId")(
  getCurrentPropertyItem,
  (item) => (item?.kind !== ItemKinds.WIDGET ? undefined : item.id),
);

export const getWidgetPropertiesById = createMarkedSelector(
  "getWidgetPropertiesById",
)(
  [getCanvasWidgets, (state: unknown, widgetId: string) => widgetId],
  (
    widgets: CanvasWidgetsReduxState,
    widgetId,
  ): WidgetProps | ReduxPageType | undefined => {
    return widgets ? widgets[widgetId] : undefined;
  },
);

const getEvaluatedEntityForPropertiesPane = proxyMemoize((state: AppState) => {
  const pane = getPropertyPaneState(state);
  if (!pane || !pane.item) {
    return undefined;
  }

  const evaluatedTree = getDataTree(state);
  const idField = [ItemKinds.WIDGET, ItemKinds.NESTED_ITEM].includes(
    pane.item.kind,
  )
    ? "widgetId"
    : "id";

  const evaluatedItem = find(evaluatedTree[pane.item.scope], {
    [idField]: pane.item.id,
  });

  return evaluatedItem;
});

export const getIsPropertyPaneVisible = createMarkedSelector(
  "getIsPropertyPaneVisible",
)(getPropertyPaneState, getCurrentItemType, (pane) => Boolean(pane.isVisible));

export const getPropertyPaneHasContents = createMarkedSelector(
  "getPropertyPaneHasContents",
)(
  getPropertyPaneState,
  getCurrentItemType,
  getCanvasWidgets,
  (pane, type, widgets) => {
    let disablePropertyPane: boolean | undefined = false;
    if (pane.item) {
      switch (pane.item.kind) {
        case ItemKinds.WIDGET:
          disablePropertyPane = Boolean(
            widgets?.[pane.item.id]?.disablePropertyPane,
          );
      }
    }
    return Boolean(pane.isVisible && pane.item && type && !disablePropertyPane);
  },
);

export const getIsPropertyValid = createSelector(
  [
    getEvaluatedEntityForPropertiesPane,
    (_state: AppState, propertyName: string) => propertyName,
  ],
  (currentEntity: any, propertyName) => {
    if (!currentEntity) return true;
    return currentEntity?.invalidProps
      ? currentEntity.invalidProps[propertyName] !== true
      : true;
  },
);

export const getPropertyValidationMessage = createSelector(
  [
    getEvaluatedEntityForPropertiesPane,
    (_: AppState, propertyName: string) => propertyName,
  ],
  (evaluatedEntity: any, propertyName) => {
    return evaluatedEntity?.validationMessages &&
      propertyName in evaluatedEntity.validationMessages
      ? evaluatedEntity.validationMessages[propertyName]
      : "";
  },
);

export const getPropertyPaneWidth = createMarkedSelector(
  "getPropertyPaneWidth",
)(
  getPropertyPaneState,
  (propertyPane: PropertyPaneReduxState) => propertyPane.width,
);

const getDisplayableParentsForWidget = (
  widgets: CanvasWidgetsReduxState,
  itemId: string,
): DisplayableParent[] => {
  const names: DisplayableParent[] = [];
  let currentWidget = widgets[itemId];
  while (currentWidget && currentWidget.parentId) {
    currentWidget = widgets[currentWidget.parentId];
    const parentWidget = widgets[currentWidget.parentId];

    // We don't skip Tabs because we want to show the tab name
    // and it's available in the nav pane
    const isTabChild = (currentWidget as any).tabName;
    const isCanvas = currentWidget?.type === WidgetTypes.CANVAS_WIDGET;
    const isSectionColumn =
      isCanvas && parentWidget.type === WidgetTypes.SECTION_WIDGET;

    const shouldSkip =
      !isTabChild &&
      ((isCanvas && !isSectionColumn) || currentWidget.disablePropertyPane);

    if (currentWidget.parentId && !shouldSkip) {
      const label = getWidgetDisplayName(currentWidget, parentWidget);

      names.push({
        ENTITY_TYPE: ENTITY_TYPE.WIDGET,
        type: currentWidget.type,
        widgetId: currentWidget.widgetId,
        label,
      });
    }
  }

  if (widgets[itemId].widgetId !== PAGE_WIDGET_ID) {
    names.push({
      ENTITY_TYPE: ENTITY_TYPE.WIDGET,
      type: WidgetTypes.PAGE_WIDGET,
      widgetId: PAGE_WIDGET_ID,
      label: widgets[PAGE_WIDGET_ID]?.widgetName || "Page",
    });
  }

  names.reverse();
  return names;
};

const getDisplayableParentsForRoutes = (
  widgets: CanvasWidgetsReduxState,
  routeDef: RouteDef,
): DisplayableParent[] => {
  const names: DisplayableParent[] = [];
  names.push({
    ENTITY_TYPE: ENTITY_TYPE.WIDGET,
    type: WidgetTypes.PAGE_WIDGET,
    widgetId: PAGE_WIDGET_ID,
    label: widgets[PAGE_WIDGET_ID]?.widgetName || "Page",
  });
  if ("widgetId" in routeDef) {
    const widget = widgets[routeDef.widgetId];
    names.push({
      ENTITY_TYPE: ENTITY_TYPE.WIDGET,
      type: widget.type,
      widgetId: routeDef.widgetId,
      label: widget.widgetName,
    });
  }
  return names;
};

const getDisplayableParentsForNestedItem = (
  widgets: CanvasWidgetsReduxState,
  itemId: string,
): DisplayableParent[] => {
  const { widgetId, path } = extractPartsFromNestedItemId(itemId);
  const widget = widgets[widgetId];

  // Start with the parents of the widget
  const names = getDisplayableParentsForWidget(widgets, widgetId);

  // Add the widget itself: it's a parent of the nested item
  const parentWidget = widgets[widget.parentId];
  names.push({
    ENTITY_TYPE: ENTITY_TYPE.WIDGET,
    type: widget.type,
    widgetId: widget.widgetId,
    label: getWidgetDisplayName(widget, parentWidget),
  });

  // Now we add all the nested items - except the leaf.
  // We skip the last item because getAllParentPaths returns the path to the leaf too
  const nestedItemParentPaths = path
    ? getAllParentPaths(path).slice(0, -1)
    : [];
  nestedItemParentPaths.forEach((path) => {
    if (!path.endsWith("]")) {
      // Skip intermediate paths which are arrays. e.g: `children[0].children`
      // Keep nested items that have metadata about the array, e.g: `children[0].children[2]`
      return;
    }

    const nestedItem = get(widgets[widgetId], path);
    const label = getNestedItemDisplayName(nestedItem, widget);
    names.push({
      ENTITY_TYPE: ENTITY_TYPE.NESTED_ITEM,
      widgetId: widget.widgetId,
      type: widget.type,
      path: path,
      label,
    });
  });
  return names;
};

export type DisplayableParent = (Widget | NestedItem) & { label: string };
export const selectDisplayableParents = createMarkedSelector(
  "selectDisplayableParents",
)(
  selectFlags,
  getCanvasWidgets,
  (_: AppState, itemKind: ItemKinds) => itemKind,
  (_: AppState, __: ItemKinds, itemId: string) => itemId,
  (state: AppState, __: ItemKinds, itemId: string) => getRoutes(state)[itemId],
  (flags, widgets, itemKind, itemId, routeDef) => {
    const itemKindMonomorphic = itemKind;
    switch (itemKindMonomorphic) {
      case ItemKinds.WIDGET: {
        return getDisplayableParentsForWidget(widgets, itemId);
      }
      case ItemKinds.ROUTE: {
        return getDisplayableParentsForRoutes(widgets, routeDef);
      }
      case ItemKinds.NESTED_ITEM: {
        return getDisplayableParentsForNestedItem(widgets, itemId);
      }
      default: {
        return [];
      }
    }
  },
);

/**
 * Used to get item scope for the current item in the property pane, but with an exception
 * for APIs, which are always in page scope. Use this if you need to know the scope of something
 * where the ItemKinds might change (property controls, for example).
 */
export const getCurrentItemScope = createSelector(
  [getPropertyPanelScope, (_: AppState, itemKind: ItemKinds) => itemKind],
  (propertyPanelScope: ApplicationScope, itemKind: ItemKinds) => {
    if (itemKind === ItemKinds.API_V1 || itemKind === ItemKinds.API_V2) {
      // TODO(API_SCOPE): actually look up the scope properly when we have that
      return ApplicationScope.PAGE;
    }
    return propertyPanelScope;
  },
);
